import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
// import constants from "./utils/constants";
import EquipmentServices from "./services/equipment.services";
import ImageServices from "./services/image.service";
import AuthServices from "./services/auth.services";

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
    key: "staplerScout",
    storage: sessionStorage,
    reducer: state => ({
        selectedEquipment: state.selectedEquipment,
        favorites: state.favorites,
        compares: state.compares,
        search: state.search,
        query: state.query,
        queryString: state.queryString,
        settings: state.settings,
        management: state.management,
    })
});

export default new Vuex.Store({
    plugins: [vuexPersist.plugin],
    state: {
        isLoggedIn: false,
        accessToken: null,
        settings: {
            itemsPerPage: 10,
            itemsSortOder: "",
            actualPage: 1,
        },
        management: {
            active: 0
        },
        loading: false,
        info: {
            color: "",
            message: ""
        },
        showSnackbar: false,
        equipments: [],
        favorites: [],
        compares: [],
        constructionYearSpan: [],
        constructionTypes: [
            "Gegengewichtstapler",
            "Hochhubwagen",
            "Niederhubwagen",
            "Schubmaststapler",
            "Schubmaststapler",
            "Kommissionierer",
            "Schlepper",
            "Komponente"
        ],
        query: [],
        queryString: "",
        selectedEquipment: null,
        images: "",
        tradeInImages: [],
        search: {
            types: [],
            constructionYearFrom: null,
            constructionYearTo: null,
            liftingHeightFrom: null,
            liftingHeightTo: null,
            loadCapacityFrom: null,
            loadCapacityTo: null,
            heightFrom: null,
            heightTo: null,
            operatingHoursFrom: null,
            operatingHoursTo: null,
            priceFrom: null,
            priceTo: null,
            propulsionType: [],
            componentType: [],
            mastType: []
        },
        searches: [],
        trashedEquipments: [],
        user: ""
    },
    mutations: {
        login: (state, data) => {
            state.isLoggedIn = true;
            state.accessToken = {created: data.created, ttl: data.ttl, id: data.id};
            state.user = data.user;
            localStorage.setItem("accessToken", JSON.stringify(state.accessToken));
            localStorage.setItem("user", JSON.stringify(state.user));
            window.location.reload()
        },
        logout: state => {
            state.isLoggedIn = false;
            state.accessToken = null;
            localStorage.removeItem("accessToken");
            localStorage.removeItem("user");
            window.location.reload()
        },
        loading: (state, data) => {
            state.loading = data;
        },
        loadEquipmentData(state, payload) {
            if (state.search.types && state.search.types.length > 0) {
                if (state.search.types[0] === "Gegengewichtstapler") {
                    if (
                        state.search.propulsionType &&
                        state.search.propulsionType.length > 0
                    ) {
                        state.equipments = payload.filter(e =>
                                e.propulsion && e.propulsion.indexOf(state.search.propulsionType[0]) > -1 &&
                            e.constructionType && e.constructionType.indexOf(state.search.types[0]) > -1
                        );
                    } else {
                        state.equipments = payload.filter(
                            p => p.constructionType.indexOf(state.search.types[0]) > -1
                        );
                    }
                } else if (state.search.types[0] === "Komponente") {
                    if (
                        state.search.componentType &&
                        state.search.componentType.length > 0
                    ) {
                        state.equipments = payload.filter(
                            e =>
                                e.component &&
                                e.component.indexOf(state.search.componentType[0]) > -1 &&
                                e.constructionType.indexOf(state.search.types[0]) > -1
                        );
                    } else {
                        state.equipments = payload.filter(
                            p => p.constructionType.indexOf(state.search.types[0]) > -1
                        );
                    }
                } else {
                    state.search.propulsionType = ["Elektro"];
                    state.equipments = payload.filter(
                        p => p.constructionType.indexOf(state.search.types[0]) > -1
                    );
                }
            } else {
                if (
                    state.search.propulsionType.length === 0 ||
                    !state.search.propulsionType
                )
                    state.equipments = payload;
                else
                    state.equipments = payload.filter(
                        p => p.propulsion && p.propulsion.indexOf(state.search.propulsionType[0]) > -1
                    );
            }
            if (state.search.mastType.length > 0)
                state.equipments = state.equipments.filter(
                    eq => eq.mastType === state.search.mastType[0]
                );
        },
        updateFavorites: (state, data) => {
            const favoriteElementIndex = state.favorites.findIndex(f => f.equipmentId === data.equipmentId);
            if (favoriteElementIndex !== -1) {
                state.favorites = state.favorites.filter(f => f.equipmentId !== data.equipmentId);
            } else {
                state.favorites.push(data);
            }
        },
        updateActualPage: (state, data) => {
            state.settings.actualPage = data;
        },
        updateSortOrder: (state, data) => {
            state.settings.itemsSortOder = data;
        },
        updateSearches: (state, data) => {
            state.searches = data;
        },
        updateItemsPerPage: (state, data) => {
            state.settings.itemsPerPage = data;
        },
        updateCompares: (state, data) => {
            const compareElementIndex = state.compares.findIndex(f => f.equipmentId === data.equipmentId);
            if (compareElementIndex !== -1) {
                state.compares = state.compares.filter(f => f.equipmentId !== data.equipmentId);
            } else {
                const images = state.images.filter(i => i.equipmentId === data.equipmentId);
                data.images = images;
                data.image = images[0];
                state.compares.push(data);
            }
        },
        updateTradInImages: (state, data) => {
            if (typeof data === 'object' && data.length === 0) {
                state.tradeInImages = [];
            } else {
                const tradeInElementIndex = state.tradeInImages.findIndex(ti => ti.name === data.name);
                if (tradeInElementIndex !== -1) {
                    state.tradeInImages[tradeInElementIndex] = data
                } else {
                    state.tradeInImages.push(data);
                }
            }
        },
        deleteTradeInImageElement: (state, data) => {
            if (state.tradeInImages && state.tradeInImages.length > 0 && state.tradeInImages[0] === null)
                state.tradeInImages = [];
            const tradeImagesElementIndex = state.tradeInImages.findIndex(
                ti => ti.name === data.name
            );
            if (tradeImagesElementIndex !== -1) state.tradeInImages.splice(tradeImagesElementIndex, 1);
        },
        updateQuery: (state, data) => {
            const queryElementIndex = state.query.findIndex(
                q => q.name === data.name
            );
            if (queryElementIndex !== -1) {
                state.query[queryElementIndex] = data;
            } else {
                state.query.push(data);
            }
        },
        deleteQueryElement: (state, data) => {
            if (state.query && state.query.length > 0 && state.query[0] === null)
                state.query = [];
            const queryElementIndex = state.query.findIndex(
                q => q.name === data.name
            );
            if (queryElementIndex !== -1) state.query.splice(queryElementIndex, 1);
        },
        setSelectedEquipmentId: (state, data) => {
            state.selectedEquipmentId = data;
        },
        setSnackbar: (state, data) => {
            state.showSnackbar = data.show;
            state.info.color = data.color;
            state.info.message = data.message;
        },
        setImages: (state, data) => {
            state.images = data;
        },
        setShowSnackbar: (state, data) => {
            state.showSnackbar = data;
        },
        resetQuery: state => {
            if (state.query && state.query[0] !== null) {
                const sortOrder = state.query.find(qe => qe.name === "sortOrder");
                state.query = [];
                state.query.push(sortOrder);
            }
        },
        loadConstructionYearSpan: (state, data) =>
            (state.constructionYearSpan = data),
        queryString: (state, data) => (state.queryString = data),
        setSelectedEquipment: (state, data) => (state.selectedEquipment = data),
        updateTypes: (state, data) => (state.search.types = data),
        updateConstructionYearFrom: (state, data) =>
            (state.search.constructionYearFrom = data),
        updateConstructionYearTo: (state, data) =>
            (state.search.constructionYearTo = data),
        updateOperatingHoursFrom: (state, data) =>
            (state.search.operatingHoursFrom = data),
        updateOperatingHoursTo: (state, data) =>
            (state.search.operatingHoursTo = data),
        updateHeightFrom: (state, data) => (state.search.heightFrom = data),
        updateHeightTo: (state, data) => (state.search.heightTo = data),
        updateLiftingHeightFrom: (state, data) =>
            (state.search.liftingHeightFrom = data),
        updateLiftingHeightTo: (state, data) =>
            (state.search.liftingHeightTo = data),
        updateLoadCapacityFrom: (state, data) =>
            (state.search.loadCapacityFrom = data),
        updateLoadCapacityTo: (state, data) => (state.search.loadCapacityTo = data),
        updatePriceFrom: (state, data) => (state.search.priceFrom = data),
        updatePriceTo: (state, data) => (state.search.priceTo = data),
        updatePropulsionType: (state, data) => (state.search.propulsionType = data),
        updateComponentType: (state, data) => (state.search.componentType = data),
        updateMastType: (state, data) => (state.search.mastType = data),
        updateActiveTab: (state, data) => (state.management.active = data),
        updateTrashedEquipments: (state, data) => (state.trashedEquipments = data),
        updateUserNotificationPreference: (state, data) => {
            state.user.enableNotifications = data
        }
    },
    actions: {
        login: (context, data) => {
            AuthServices.login({
                email: data.email,
                password: data.password
            })
                .then(response => {
                    context.commit("login", response.data);
                    context.dispatch("getEquipmentData");
                    context.dispatch("showSnackbar", {
                        color: "success",
                        message: "Anmeldung erfolgreich."
                    });
                })
                .catch(() => {
                    context.dispatch("showSnackbar", {
                        color: "error",
                        message: "Anmeldung fehlgeschlagen."
                    });
                });
        },
        logout: context => {
            AuthServices.logout(
                context.state.accessToken ? context.state.accessToken.id : null
            )
                .then(() => {
                    context.commit("logout");
                    context.dispatch("showSnackbar", {
                        color: "success",
                        message: "Erfolgreich abgemeldet."
                    });
                    context.dispatch("getEquipmentData");
                })
                .catch(() => {
                    context.dispatch("showSnackbar", {
                        color: "error",
                        message: "Abmelden fehlgeschlagen."
                    });
                });
        },
        showSnackbar: (context, payload) => {
            context.commit("setSnackbar", {
                show: true,
                color: payload.color,
                message: payload.message
            });
        },
        hideSnackbar: context => {
            context.commit("setSnackbar", {show: false, color: "", message: ""});
        },
        getEquipmentData: context => {
            let queryString = "Equipments";
            if (
                context.state.query.length > 0 &&
                context.state.query[0] !== undefined &&
                context.state.query[0] !== null
            ) {
                const queryStringArray = context.state.query.map(q => q.query);
                queryString += "?";
                queryString += queryStringArray.join("&");
            }
            context.commit("queryString", queryString);
            context.commit("loading", true);
            ImageServices.getImages(context.state.accessToken).then(response => {
                const imageData = response.data;
                context.commit("setImages", imageData);
                EquipmentServices.getEquipments(context.state.accessToken, queryString)
                    .then(response => {
                        for (let item of response.data.data) {
                            context.commit("updateTrashedEquipments", response.data.data.filter(e => e.trashed == 1))
                            switch (item.propulsion) {
                                case "ELECTRIC":
                                    item.propulsion = "Elektro";
                                    break;
                                case "GAS":
                                    item.propulsion = "Gas";
                                    break;
                                case "DIESEL":
                                    item.propulsion = "Diesel";
                                    break;
                                default:
                                    break;
                            }
                            const images = context.state.images.filter(
                                img => img.equipmentId === item.equipmentId
                            );
                            const ic = images;
                            item.imagesReal = ic;
                            item.image = ic[0];
                        }
                        context.commit("loadEquipmentData", response.data.data);
                    })
                    .then(() => context.commit("loading", false));
            });
        },
        getConstructionYearsSpan: context => {
            const constructionYears = [{text: "Beliebig", value: undefined}];
            const max = new Date(Date.now()).getFullYear();
            const min = max - 16;
            for (let el = max; el >= min; el--)
                constructionYears.push({text: el, value: el});
            context.commit("loadConstructionYearSpan", constructionYears);
        },
        unsetSearchParameters: context => {
            context.commit("updateTypes", []);
            context.commit("updatePropulsionType", undefined);
            context.commit("updateConstructionYearFrom", undefined);
            context.commit("updateConstructionYearTo", undefined);
            context.commit("updateLiftingHeightFrom", undefined);
            context.commit("updateLiftingHeightTo", undefined);
            context.commit("updateLoadCapacityFrom", undefined);
            context.commit("updateLoadCapacityTo", undefined);
            context.commit("updateOperatingHoursTo", undefined);
            context.commit("updateOperatingHoursFrom", undefined);
            context.commit("updateHeightTo", undefined);
            context.commit("updateHeightFrom", undefined);
            context.commit("updatePriceFrom", undefined);
            context.commit("updatePriceTo", undefined);
            context.commit("updatePropulsionType", []);
            context.commit("updateMastType", []);
            context.commit("updateComponentType", [])
        },
        constructQuery: (context, data) => {
            let query = ''
            if (data.from !== null && (data.to === null || data.to === undefined)) {
                query = `filter[where][${data.type}][gte]=${data.from}`
            } else if (
                (data.from === null || data.from === undefined) &&
                data.to !== null
            ) {
                query = `filter[where][${data.type}][lte]=${data.to}`
            } else if (
                data.from !== null &&
                data.from !== undefined &&
                (data.to !== null && data.to !== undefined)
            ) {
                query = `filter[where][${data.type}][between][0]=${data.from}&filter[where][${data.type}][between][1]=${data.to}`
            }
            context.commit("updateQuery", {name: data.type, query: query});
        },
        setTypes: (context, type) => {
            if (context.state.search.types.includes(type) || type === "Beliebig")
                context.commit("updateTypes", []);
            else {
                context.commit("updateTypes", [type]);
            }
        },
        setConstructionYear: (context, constructionYear) => {
            if (constructionYear.name === "from")
                context.commit("updateConstructionYearFrom", constructionYear.value);
            if (constructionYear.name === "to")
                context.commit("updateConstructionYearTo", constructionYear.value);
        },
        setLiftingHeight: (context, liftingHeight) => {
            if (liftingHeight.name === "from")
                context.commit("updateLiftingHeightFrom", liftingHeight.value);
            if (liftingHeight.name === "to")
                context.commit("updateLiftingHeightTo", liftingHeight.value);
        },
        setHeight: (context, height) => {
            if (height.name === "from")
                context.commit("updateHeightFrom", height.value);
            if (height.name === "to") context.commit("updateHeightTo", height.value);
        },
        setOperatingHours: (context, operatingHours) => {
            if (operatingHours.name === "from")
                context.commit("updateOperatingHoursFrom", operatingHours.value);
            if (operatingHours.name === "to")
                context.commit("updateOperatingHoursTo", operatingHours.value);
        },
        setLoadCapacity: (context, loadCapacity) => {
            if (loadCapacity.name === "from")
                context.commit("updateLoadCapacityFrom", loadCapacity.value);
            if (loadCapacity.name === "to")
                context.commit("updateLoadCapacityTo", loadCapacity.value);
        },
        setPrice: (context, price) => {
            if (price.name === "from") context.commit("updatePriceFrom", price.value);
            if (price.name === "to") context.commit("updatePriceTo", price.value);
        },
        setPropulsionType: (context, propulsionType) => {
            if (propulsionType == "Beliebig")
                context.commit("updatePropulsionType", []);
            else {
                context.commit("updatePropulsionType", [propulsionType]);
            }
        },
        setComponentType: (context, componentType) => {
            if (componentType == "Beliebig")
                context.commit("updateComponentType", []);
            else {
                context.commit("updateComponentType", [componentType]);
            }
        },
        setMastType: (context, mastType) => {
            if (mastType == "Beliebig" || mastType == "") context.commit("updateMastType", []);
            else {
                context.commit("updateMastType", [mastType]);
            }
        },
        setActiveTab: (context, active) => {
            context.commit("updateActiveTab", active);
        },
    },
    getters: {
        loginState: state => {
            let token = state.accessToken;
            if (!token) {
                token = localStorage.getItem("accessToken");
                token = JSON.parse(token);
                state.accessToken = token;
            }
            if (!token) {
                return false;
            } else {
                let user = JSON.parse(localStorage.getItem("user"));
                state.user = user;
            }
            // Now - validUntil
            if (Date.now() - (Date.parse(token.created) + parseInt(token.ttl)) < 0) {
                state.isLoggedIn = true;
            } else {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("user");
                state.isLoggedIn = false;
            }
            return state.isLoggedIn;
        },
        selectedEQ: state => {
            return state.selectedEquipment;
        },
    }
});
